export function getFileType(file) {
    switch (file.streamable) {
        case 'video': return 'Fichier vidéo';
        case 'audio': return 'Fichier audio';
        case 'text': return 'Fichier texte';
        default: return 'Fichier'
    }
};

export function getFileLink(torrentId, fileType, fileName, filePath, fileToken) {
    const encodedFilePath = encodeURIComponent(filePath);
    const encodedFileName = encodeURIComponent(fileName);
    return `/api/jackerta/torrent/${torrentId}/${fileType === 'directory' ? 'archive' : 'download'}?access_token=${fileToken}&path=${encodedFilePath}&name=${encodedFileName}`;
};