import React, { useState } from 'react';
import Card from '../../../Components/Card/Card';
import useResponsive from '../../../hooks/useResponsive';
import './TorrentComplete.css';
import TorrentLines from './TorrentLines/TorrentLines';
import TorrentHexagones from './TorrentHexagones/TorrentHexagones';
import TorrentCards from './TorrentCards/TorrentCards';

export function TorrentCompleteTypeChoice({name, items, defaultValue, onChange}) {

    const [value, setValue] = useState(defaultValue);

    const select = (ev) => {
        setValue(ev.target.value);
        onChange && onChange(ev.target.value);
    };
    return <div className='TorrentCompleteTypeChoice'>
        {items.map((item) => <div key={item.value} className={'TorrentCompleteTypeChoice-item' + (item.value === value ? ' selected' : '')}>
            <label>{item.label}</label>
            <input type="radio" name={name} value={item.value} checked={item.value === value} onChange={select} />
        </div>)}
    </div>
}

export default function TorrentComplete({torrents, isDeletable, isShareable, isUserVisible}) {

    const [type, setType] = useState(localStorage.getItem('torrent-complete-type') || 'line');
    const {breakpoint} = useResponsive();

    const updateType = (type) => {
        localStorage.setItem('torrent-complete-type', type);
        setType(type);
    }

    const items = [{
        label: 'Ligne',
        value: 'line'
    },
    {
        label: 'Card',
        value: 'card'
    },
    {
        label: 'Hexagone',
        value: 'hexagone'
    }];
    return <Card
        label="Téléchargés"
        actions={<TorrentCompleteTypeChoice name="torrent-type" items={items} defaultValue={type} onChange={updateType}></TorrentCompleteTypeChoice>}
    >
        {
        {
          'hexagone': <TorrentHexagones torrents={torrents} isDeletable={isDeletable} isShareable={isShareable} isUserVisible={isUserVisible}></TorrentHexagones>,
          'card': <TorrentCards torrents={torrents} isDeletable={isDeletable} isShareable={isShareable} isUserVisible={isUserVisible} />,
          'line': breakpoint === 'xl'
            ? <TorrentLines torrents={torrents} isDeletable={isDeletable} isShareable={isShareable} isUserVisible={isUserVisible} />
            : <TorrentCards torrents={torrents} isDeletable={isDeletable} isShareable={isShareable} isUserVisible={isUserVisible}></TorrentCards>
        }[type]
      }
    </Card>
}