import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  info: null,
  torrent: null,
  rss: [],
  loading: false,
  loaded: false
};
export const userSlice = createSlice({
  name: 'user',
  initialState: {...initialState},
  reducers: {
    reset: (state) => {
      state.info = null;
      state.torrent = null;
      state.rss = null;
      state.loading = false;
      state.loaded = false;
    },
    getUser: (state) => {
      state.loading = true;
      state.loaded = false;
    },
    getUserSuccess: (state, {payload}) => {
      state.info = payload.info;
      state.torrent = payload.torrent;
      state.rss = payload.rss;
      state.loading = false;
      state.loaded = true;
    },
    setTorrent: (state, {payload}) => {
      state.torrent = payload;
    },
    setTorrentShare: (state, {payload}) => {
      const shareData = state.info.datas.find((data) => data.type === 'share');
      shareData.value = payload ? 1 : 0;
    },
    createRssFlowSuccess: (state, {payload}) => {
      state.rss = [...state.rss, payload];
    },
    emptyRssFlowSuccess: (state, {payload}) => {
      const flow = state.rss.find((flow) => flow._id === payload.flowId);
      if(flow) {
        flow.content = [];
      }
    },
    deleteRssFlowSuccess: (state, {payload}) => {
      state.rss = state.rss.filter((flow) => flow._id !== payload.flowId);
    },
    appendRssFlowFeedSuccess: (state, {payload}) => {
      const flow = state.rss.find((flow) => flow._id === payload.rss);
      if(flow) {
        flow.feeds = [...flow.feeds, payload];
      }
    },
    removeRssFlowFeedSuccess: (state, {payload}) => {
      const flow = state.rss.find((flow) => flow._id === payload.flowId);
      if(flow) {
        flow.feeds = flow.feeds.filter((feed) => feed._id !== payload.feedId);
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { reset, getUser, getUserSuccess, setTorrent, setTorrentShare, createRssFlowSuccess, emptyRssFlowSuccess, deleteRssFlowSuccess, appendRssFlowFeedSuccess, removeRssFlowFeedSuccess } = userSlice.actions

export default userSlice.reducer