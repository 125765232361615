import { useEffect, useState } from "react";

export default function useResponsive() {

    const getBreakpoint = (width) => {
        if(width > 1199) {
            return 'xl';
        } else if(width > 991) {
            return 'lg';
        } else if(width > 767) {
            return 'md';
        } else if(width > 575) {
            return 'sm';
        } else {
            return 'xs';
        }
    };

    const [breakpoint, setBreakpoint] = useState(getBreakpoint(window.innerWidth));
    const [width, setWidth] = useState(getBreakpoint(window.innerWidth));

    const handleResize = (event) => {
        setWidth(window.innerWidth);
        setBreakpoint(getBreakpoint(window.innerWidth));
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize, true);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { width, breakpoint };
}