import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  roles: {
    loading: false,
    loaded: false,
    data: []
  },
  users: {
    loading: false,
    loaded: false,
    data: []
  },
  torrents: {
    loading: false,
    loaded: false,
    data: []
  }
};
export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    reset: (state) => {
      state.roles = initialState.roles;
      state.users = initialState.users;
      state.torrents = initialState.torrents;
    },
    getRoles: (state) => {
      state.roles.data = [];
      state.roles.loading = true;
      state.roles.loaded = false;
    },
    getRolesSuccess: (state, {payload}) => {
      state.roles.data = payload;
      state.roles.loading = false;
      state.roles.loaded = true;
    },
    createRole: (state) => {
      state.roles.loading = true;
      state.roles.loaded = false;
    },
    createRoleSuccess: (state, {payload}) => {
      state.roles.data = [...state.roles.data, payload];
      state.roles.loading = false;
      state.roles.loaded = true;
    },
    createRoleLimit: (state) => {
      state.roles.loading = true;
      state.roles.loaded = false;
    },
    createRoleLimitSuccess: (state, {payload}) => {
      const role = state.roles.data.find((role) => role._id === payload.roleId);
      role.limits = [...role.limits, payload.limit];
      state.roles.data = [...state.roles.data];
      state.roles.loading = false;
      state.roles.loaded = true;
    },
    updateRoleLimit: (state) => {
      state.roles.loading = true;
      state.roles.loaded = false;
    },
    updateRoleLimitSuccess: (state, {payload}) => {
      const role = state.roles.data.find((role) => role._id === payload.roleId);
      const limit = role.limits.find((limit) => limit.type === payload.limit.type);
      limit.value = payload.limit.value;
      state.roles.data = [...state.roles.data];
      state.roles.loading = false;
      state.roles.loaded = true;
    },
    removeRoleLimit: (state) => {
      state.roles.loading = true;
      state.roles.loaded = false;
    },
    removeRoleLimitSuccess: (state, {payload}) => {
      const role = state.roles.data.find((role) => role._id === payload.roleId);
      const limits = role.limits.filter((limit) => limit._id !== payload.limitId);
      role.limits = limits;
      state.roles.data = [...state.roles.data];
      state.roles.loading = false;
      state.roles.loaded = true;
    },
    getUsers: (state) => {
      state.users.data = [];
      state.users.loading = true;
      state.users.loaded = false;
    },
    getUsersSuccess: (state, {payload}) => {
      state.users.data = payload;
      state.users.loading = false;
      state.users.loaded = true;
    },
    updateUserRole: (state) => {
      state.users.loading = true;
      state.users.loaded = false;
    },
    updateUserRoleSuccess: (state, {payload}) => {
      const user = state.users.data.find((user) => user._id === payload.userId);
      user.role = payload.role;
      state.users.loading = false;
      state.users.loaded = true;
    },
    updateUserValidity: (state) => {
      state.users.loading = true;
      state.users.loaded = false;
    },
    updateUserValiditySuccess: (state, {payload}) => {
      const user = state.users.data.find((user) => user._id === payload.userId);
      user.validity = payload.validity;
      state.users.loading = false;
      state.users.loaded = true;
    },
    deleteUser: (state) => {
      state.users.loading = true;
      state.users.loaded = false;
    },
    deleteUserSuccess: (state, {payload}) => {
      state.users.data = state.users.data.filter((user) => user._id !== payload.userId);
      state.users.loading = false;
      state.users.loaded = true;
    },
    getTorrents: (state) => {
      state.torrents.data = [];
      state.torrents.loading = true;
      state.torrents.loaded = false;
    },
    getTorrentsSuccess: (state, {payload}) => {
      state.torrents.data = payload;
      state.torrents.loading = false;
      state.torrents.loaded = true;
    },
    updateTorrentsUser: (state) => {
      state.torrents.loading = true;
      state.torrents.loaded = false;
    },
    updateTorrentsUserSuccess: (state, {payload}) => {
      console.log('payload', payload, state.torrents.data);
      const torrent = state.torrents.data.find((torrent) => torrent._id === payload.torrentId);
      torrent.user = state.users.data.find((user) => user._id === payload.user);
      state.torrents.loading = false;
      state.torrents.loaded = true;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  reset, getRoles, getRolesSuccess, createRole, createRoleSuccess, createRoleLimit, createRoleLimitSuccess, updateRoleLimit, updateRoleLimitSuccess, removeRoleLimit, removeRoleLimitSuccess,
  getUsers, getUsersSuccess, updateUserRole, updateUserRoleSuccess, updateUserValidity, updateUserValiditySuccess, deleteUser, deleteUserSuccess,
  getTorrents, getTorrentsSuccess, updateTorrentsUser, updateTorrentsUserSuccess
} = adminSlice.actions

export default adminSlice.reducer