import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import convertBitToBytes from '../../../../../utils/convertBitToBytes.utils';
import TorrentService from '../../../Torrent.service';
import './TorrentCard.css';
import eta from '../../../../../utils/eta.utils';
import Progress from '../../../../../Components/Progress/Progress';
export default function TorrentCard({torrent, isDeletable, isShareable, isUserVisible}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isShare, setIsShared] = useState(torrent.shared);

    useEffect(() => {
        setIsShared(torrent.shared);
    }, [torrent.shared]);

    const goToDetail = () => {
        navigate(`/torrent/${torrent._id}`);
    };

    const updateShare = (ev) => {
        TorrentService.updateShare(dispatch, torrent._id, ev.target.checked);
    };

    const remove = (ev) => {
        ev.stopPropagation();
        TorrentService.delete(dispatch, torrent._id);
    };

    return <div className='TorrentCard' onClick={goToDetail}>
        <div className='TorrentCard-progress'><Progress percent={(torrent.datas.percent || 0) * 100} hidePercent={true}></Progress></div>
        <div className='TorrentCard-name'>{torrent.datas.name}</div>
        <div className='TorrentCard-eta TorrentCard-item'><span className='TorrentCard-label'>Restant:</span><span className='TorrentCard-value'>{eta(torrent.datas.eta)}</span></div>
        <div className='TorrentCard-size TorrentCard-item'><span className='TorrentCard-label'>Vitesse:</span><span className='TorrentCard-value'>{convertBitToBytes(torrent.datas.downloading)}/s</span></div>
        <div className='TorrentCard-size TorrentCard-item'><span className='TorrentCard-label'>Taille:</span><span className='TorrentCard-value'>{convertBitToBytes(torrent.datas.size)}</span></div>
        <div className='TorrentCard-date TorrentCard-item'><span className='TorrentCard-label'>Date d'ajout:</span><span className='TorrentCard-value'>{dayjs(torrent.date).format('DD/MM/YYYY')}</span></div>
        <div className='TorrentCard-url TorrentCard-item'><span className='TorrentCard-label'>Tracker:</span><span className='TorrentCard-value'><a onClick={(ev) => ev.stopPropagation()} href={torrent.url}>{torrent.source}</a></span></div>
        {isShareable && <div className='TorrentCard-shared TorrentCard-item'>
            <span className='TorrentCard-label'>Partagé:</span><input className='TorrentCard-value' type="checkbox" checked={isShare} onChange={updateShare} onClick={(ev) => ev.stopPropagation()}/>
        </div>}
        {isDeletable && <div className='TorrentCard-item TorrentCard-remove'><button className='block sm danger' onClick={remove}>Supprimer</button></div>}
        {isUserVisible && <div>{torrent.user.name}</div>}
    </div>
}