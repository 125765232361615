export function getToken() {
    return localStorage.getItem('token');
}
export function setToken(token) {
    return localStorage.setItem('token', token);
}

export function removeToken() {
    return localStorage.removeItem('token');
}

export function decode(token) {
    return JSON.parse(window.atob(token.split('.')[1]));
}

export function isTokenExpired(token) {
    const decodeToken = decode(token);
    return (decodeToken.exp * 1000) < new Date().getTime();
}