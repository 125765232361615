import React from 'react';
import './Progress.css';
function Progress(props) {
    
    const getState = (value) => {

        if(props.isReversed) {
            if(value < 33) {
                return 'success';
            } else if(value < 80) {
                return 'warning';
            } else {
                return 'danger';
            }
        } else {
            if(value < 33) {
                return 'danger';
            } else if(value < 80) {
                return 'warning';
            } else {
                return 'success';
            }
        }
        
    }
    return <div className='Progress'>
        {props.label && <label>{props.label}</label>}
        <div className="Progress-bar">
            <div className={"Progress-bar-value Progress-bar-value-" + getState(props.percent)} style={{width: props.percent + '%'}}></div>
            <div className='Progress-bar-info'>{!props.hidePercent && props.percent + '%'} {props.value && props.max && '(' + props.value + '/' + props.max + ')'}</div>
        </div>
    </div>
}

export default Progress;