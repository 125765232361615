import React from 'react';
import './Admin.css';
import Roles from './Roles/Roles';
import Users from './Users/Users';
import Torrents from './Torrents/Torrents';
export default function Admin() {
    return <div className="Admin">
        <Roles></Roles>
        <Users></Users>
        <Torrents></Torrents>
    </div>;
}