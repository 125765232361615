import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Shared.css';
import TorrentService from '../Torrent.service';
import TorrentComplete from '../TorrentComplete/TorrentComplete';
import TorrentIncomplete from '../TorrentIncomplete/TorrentIncomplete';
import Loader from '../../../Components/Loader/Loader';

function TorrentShared() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isLoaded = useSelector((state) => state.torrent.list.loaded);
  const isLoading = useSelector((state) => state.torrent.list.loading);
  const privates = useSelector((state) => state.torrent.list.shareds);

  const getCompletes = () => privates.filter((torrent) => torrent.datas.percent === 1);
  const getIncompletes = () => privates.filter((torrent) => torrent.datas.percent < 1);

  useEffect(() => {
    if(!isLoaded && !isLoading && user.info && user.info._id) {
      TorrentService.getAll(dispatch, user.info._id);
    }
  }, [user, isLoaded, isLoading]);

  return (
    <div className="TorrentShared">
      {!!getIncompletes().length && <TorrentIncomplete torrents={getIncompletes()} isDeletable={false} isShareable={false} isUserVisible={true}></TorrentIncomplete>}
      {!!getCompletes().length && <TorrentComplete torrents={getCompletes()} isDeletable={false} isShareable={false} isUserVisible={true}></TorrentComplete>}
      {!getIncompletes().length && !getCompletes().length && <p className='TorrentShared-empty text-center'>Aucun torrent partagé !</p>}
      <Loader hasText="true" isVisible={isLoading}></Loader>
    </div>
  );
}

export default TorrentShared;
