import axios from "axios";
import EnvironementService from "./Environment.service";
import { getToken } from "./Token.service";

export default class ApiService {

    static createHttpRequest = (method, uri, data) => {
        const controller = new AbortController();
        const config = {
            signal: controller.signal,
            headers: { Authorization: `Bearer ${getToken()}` }
        };
        const url = `${EnvironementService.config.origin}${uri}`;

        const params = [url];

        if(method !== 'get' && method !== 'delete') {
            params.push(data);
        }
        params.push(config);
        return {
            request: axios[method](...params),
            abord: () => {controller.abort()}
        };
    }

    static get(uri) {
        return ApiService.createHttpRequest('get', uri)
    };
    
    static post(uri, data) {
        return ApiService.createHttpRequest('post', uri, data);
    };

    static put(uri, data) {
        return ApiService.createHttpRequest('put', uri, data);
    };

    static delete(uri) {
        return ApiService.createHttpRequest('delete', uri);
    };
}