import React from 'react';
import { Navigate } from 'react-router-dom';
import useGuard from '../hooks/useGuard';
function HasRoleLevelGuard({ children, level }){

    const guard = useGuard();

    if (!guard.hasRoleLevelGuard(level)) {
        return <Navigate to='/' />
    }

    return children;
}

export default HasRoleLevelGuard;