import React from 'react';
import { useAuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { isTokenExpired } from '../services/Token.service';
function AuthGuard({ children }){
    
    const auth = useAuthContext();

    if (!auth.token || isTokenExpired(auth.token)) {
        return <Navigate to='/connexion' />
    }
    return children;
}

export default AuthGuard;