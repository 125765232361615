import React from 'react';
import Card from '../../../Components/Card/Card';
import useResponsive from '../../../hooks/useResponsive';
import TorrentCards from './TorrentCards/TorrentCards';
import './TorrentIncomplete.css';
import TorrentLines from './TorrentLines/TorrentLines';

function TorrentIncomplete(props) {

  const {breakpoint} = useResponsive();

  return <Card label="En cours de téléchargement">
    {
      breakpoint === 'xl'
        ? <TorrentLines {...props}></TorrentLines>
        : <TorrentCards {...props}></TorrentCards>
    }
  </Card>;
}

export default TorrentIncomplete;
