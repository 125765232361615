import React from 'react';
import dayjs from 'dayjs';
import convertBitToBytes from '../../../../../utils/convertBitToBytes.utils';
import './TorrentHexagone.css';
import EnvironementService from '../../../../../services/Environment.service';
import { NavLink } from 'react-router-dom';
import TorrentService from '../../../Torrent.service';
import { useDispatch } from 'react-redux';

export default function TorrentHexagone({torrent, isDeletable, isShareable, isUserVisible}) {

    const dispatch = useDispatch();
    const updateShare = (ev) => {
        TorrentService.updateShare(dispatch, torrent._id, ev.target.checked);
    };

    const remove = (ev) => {
        ev.stopPropagation();
        TorrentService.delete(dispatch, torrent._id);
    };

    const getRatioState = () => {
        if(torrent.datas.ratio < 0.50) return 'danger';
        if(torrent.datas.ratio < 1) return 'warning';
        if(torrent.datas.ratio < 2) return 'primary';
        return 'success';
    }

    return <div className='TorrentHexagone'>
        <div className='TorrentHexagone-hexagone'>
            <div className='TorrentHexagone-container'>
                <img className="TorrentHexagone-image"
                    onError={(ev) => {ev.target.src = ''; ev.target.alt = '';}}
                    src={`${EnvironementService.config.origin}/api/jackerta/torrent/${torrent._id}/picture`}
                    alt={torrent.datas.name} />
                <div className="TorrentHexagone-top">
                    {
                        !!torrent.url
                        ? <a ng-if="torrent.url" className="TorrentHexagone-descriptif" href={ torrent.url } target="_blank" rel="noopener noreferrer">{ torrent.source }</a>
                        :<span ng-if="!torrent.url" className="TorrentHexagone-not-tracker">{ torrent.source }</span>
                    }
                </div>
                <NavLink className='TorrentHexagone-info' to={`/torrent/${torrent._id}`}>
                    <div className="TorrentHexagone-info-top">
                        <div className="TorrentHexagone-info-date" title="date d'ajout">{ dayjs(torrent.datas.date).format("d MMM YYYY") }</div>
                        <div className="TorrentHexagone-info-taille" title="taille">{ convertBitToBytes(torrent.datas.size) }</div>
                    </div>
                    <div className="TorrentHexagone-info-center">
                        <div className="TorrentHexagone-info-name">{ torrent.datas.name }</div>
                    </div>
                    <div className="TorrentHexagone-info-bottom">
                        <div className="TorrentHexagone-info-uploaded" title="Uploadé">{ convertBitToBytes(torrent.datas.uploaded) }</div>
                        <div className={`TorrentHexagone-info-ratio TorrentHexagone-info-ratio--${getRatioState()}`} title="ratio">
                            { torrent.datas.ratio }
                        </div>
                    </div>
                </NavLink>
                <div className="TorrentHexagone-bottom">
		 		    {isShareable && <button type="button" onClick={updateShare} className={`TorrentHexagone-privacy ${torrent.shared ? 'success': 'warning'}`}>
		 			    Partagé : {torrent.shared ? 'Oui' : 'Non'}
		 		    </button> }
            	    {isDeletable && <button className="TorrentHexagone-remove" onClick={remove}>X</button>}
                    
            	    {isUserVisible && <span className="TorrentHexagone-user">{torrent.user.name}</span>}
            </div>
            </div>
        </div>
    </div>
}