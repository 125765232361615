import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import './Detail.css';
import Card from '../../../Components/Card/Card';
import TorrentService from '../Torrent.service';
import UserService from '../../../services/User.service';
import FileLine from './FileLine/FileLine';
import { getFileLink } from '../../../utils/torrentDetail.utils';
import { getToken } from '../../../services/Token.service';
import useResponsive from '../../../hooks/useResponsive';
import FileCard from './FileCard/FileCard';
import EnvironementService from '../../../services/Environment.service';

export default function TorrentDetail() {

    const dispatch = useDispatch();
    const params = useParams();
    const {breakpoint} = useResponsive();
    const [searchParams, setSearchParams] = useSearchParams();

    const detail = useSelector((state) => state.torrent.detail.data);
    const loading = useSelector((state) => state.torrent.detail.loading);
    const loaded = useSelector((state) => state.torrent.detail.loaded);
    
    const files = useSelector((state) => state.torrent.detail.files.data);
    const filesLoaded = useSelector((state) => state.torrent.detail.files.loaded);

    const rss = useSelector((state) => state.user.rss);

    useEffect(() => {
        if(!searchParams.get('path')) {
            setSearchParams({path: '/'})
        }
        TorrentService.getDetailFiles(dispatch, params.id, searchParams.get('path'));
    }, [searchParams.get('path')]);

    useEffect(() => {
        TorrentService.getDetail(dispatch, params.id);
    }, []);

    const getLabel = () => {
        if(loading) {
            return 'Chargement en cours...';
        } else if (loaded) {
            return detail.datas.name;
        } else {
            return '';
        }
        
    };

    const addRssFeed = (flowId, file) => {
        UserService.appendRssFlowFeed(dispatch, flowId, file.name, getFileLink(params.id, file.type, file.name, searchParams.get('path'), getToken()));
    };

    const goToPrevious = () => {
        const newPath = searchParams.get('path').split('/');
        newPath.pop()
        setSearchParams({path: newPath.join('/')})
    }
    const goTo = (file) => {
        if(file.type === 'directory') {
            setSearchParams({path: searchParams.get('path') === '/' ? `${searchParams.get('path')}${file.name}` : `${searchParams.get('path')}/${file.name}`});
        }
    };

    const getArchiveName = () => {
        if(!!searchParams.get('path')) {
            const splittedPath = searchParams.get('path').split('/');
            return splittedPath.pop();
        } else {
            return '';
        }
        
    };

    const getArchivePath = () => {
        if(!!searchParams.get('path')) {
            console.log(searchParams.get('path'));
            const isRoot = searchParams.get('path') === '/';
            const splittedPath = searchParams.get('path').split('/');
            splittedPath.pop();
            return isRoot ? '/' : splittedPath.join('/');
        } else {
            return '/';
        }
    };

    return <div className='TorrentDetail'>
        <Card label={getLabel()} actions={<div className='TorrentDetail-archive'><a
                className='block'
                onClick={(ev) => {ev.stopPropagation()}}
                href={EnvironementService.config.origin + getFileLink(params.id, 'directory', getArchiveName(), getArchivePath(), getToken())}
                target="_blank"
                rel="noopener noreferrer">
                <button className='success block'>{'Télécharger le dossier complet'}</button>
            </a>
        </div>}>
            {searchParams.get('path') !== '/' && <p className='TorrentDetail-path' onClick={goToPrevious}>&lt; {searchParams.get('path')}</p>}
            {loaded ? <div className='TorrentDetail-content'>
                { breakpoint === 'xl' ? <table>
                    <thead>
                        <tr>
                            <th className='TorrentDetail-name'>Nom</th>
                            <th>Taille</th>
                            <th>Type</th>
                            <th className='TorrentDetail-download'>Télécharger</th>
                            {rss && rss.length > 0 && <th>RSS</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {filesLoaded && files.content.map((file) => <FileLine
                            key={file.name}
                            torrentId={params.id}
                            path={searchParams.get('path')}
                            file={file}
                            goTo={goTo}
                            addRssFeed={addRssFeed}
                            rss={rss}
                        ></FileLine> )}
                    </tbody>
                </table> : <div className='TorrentDetail-cards'>{filesLoaded && files.content.map((file) => <FileCard
                            key={file.name}
                            torrentId={params.id}
                            path={searchParams.get('path')}
                            file={file}
                            goTo={goTo}
                            addRssFeed={addRssFeed}
                            rss={rss}
                        ></FileCard> )}</div>
                    }
            </div>
                 : loading ? 'Chargement en cours...': 'Une erreur est survenue'}
        </Card>
    </div>
}