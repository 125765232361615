import React, { useEffect, useState } from 'react';
import './TorrentLine.css';
import Progress from '../../../../../Components/Progress/Progress';
import TorrentService from '../../../Torrent.service';
import { useDispatch } from 'react-redux';
import convertBitToBytes from '../../../../../utils/convertBitToBytes.utils';
import eta from '../../../../../utils/eta.utils';
function TorrentLine({isDeletable, isShareable, isUserVisible, torrent}) {

  const dispatch = useDispatch();

  const [isShared, setIsShared] = useState(torrent.shared);

  useEffect(() => {
    setIsShared(torrent.shared);
  }, [torrent.shared]);

  const updateShare = (ev) => {
    TorrentService.updateShare(dispatch, torrent._id, ev.target.checked);
  };

  const deleteTorrent = (ev) => {
    ev.stopPropagation();
    TorrentService.delete(dispatch, torrent._id);
  }
  
  return (
    <tr className="TorrentLine">
      <td className="TorrentLine-progress"><Progress percent={(torrent.datas.percent || 0) * 100} hidePercent={true}></Progress></td>
      <td className="TorrentLine-name"><a href={torrent.url} target="_blank" rel="noopener noreferrer">{torrent.datas.name}</a></td>
      <td className="TorrentLine-eta">{eta(torrent.datas.eta)}</td>
      <td className="TorrentLine-speed">{convertBitToBytes(torrent.datas.downloading)}/s</td>
      <td className="TorrentLine-size">{convertBitToBytes(torrent.datas.size)}</td>
      {isShareable && <td className="TorrentLine-share"><input type="checkbox" checked={isShared} onChange={updateShare} /></td>}
      {isDeletable && <td className="TorrentLine-remove"><button className="danger block sm" onClick={deleteTorrent}>Supprimer</button></td>}
      {isUserVisible && <td>{torrent.user.name}</td>}
    </tr>
  );
}

export default TorrentLine;
