import React from 'react';
import { useState } from 'react';
import './Register.css';
import {useAuthContext} from '../../context/AuthContext';
import { useDispatch } from 'react-redux'
import UserService from '../../services/User.service';
import Card from '../../Components/Card/Card';

function Register() {

  const dispatch = useDispatch();

  const auth = useAuthContext();

  const [name, setName] = useState();

  const [login, setLogin] = useState();
  
  const [password, setPassword] = useState();

  const [passwordConfirmation, setPasswordConfirmation] = useState();

  const [isSamePasswordError, setIsSamePasswordError] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsSamePasswordError(false);
  };

  const handlePasswordConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);
    setIsSamePasswordError(false);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const isSamePassword = password === passwordConfirmation;
    setIsSamePasswordError(!isSamePassword);
    if(!isSamePassword) {
      return;
    }
    auth.register(name, login, password, passwordConfirmation)
      .then(() => UserService.getUser(dispatch));
  };

  return (
    <div className="Register grid-xs">
      <Card label="Inscription">
        <form className="Register-form" onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Prénom" onChange={handleNameChange} required />
          <input type="email" name="email" placeholder="Email" onChange={handleEmailChange} required />
          <input type="password" name="password" placeholder="Mot de passe" onChange={handlePasswordChange} required />
          <input type="password" name="passwordConfirmation" placeholder="Confirmation du mot de passe" onChange={handlePasswordConfirmationChange} required />
          {isSamePasswordError && <p className='Register-error'>Les mots de passe ne correspondent pas !</p>}
          <button type="submit">S'inscrire</button>
        </form>
      </Card>

    </div>
  );
}

export default Register;
