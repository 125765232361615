import sha1 from 'sha-1';
import { appendRssFlowFeedSuccess, createRssFlowSuccess, deleteRssFlowSuccess, emptyRssFlowSuccess, getUser, getUserSuccess, removeRssFlowFeedSuccess, setTorrentShare } from '../User.slice';
import ApiService from './Api.service';

export default class UserService {

    static register(name, email, password) {
        return ApiService.post('/api/users', {name, email, password: sha1(password)}).request;
    }
    static connect(email, password) {
        return ApiService.post('/auth/local', {email, password: sha1(password)}).request;
    }

    static getUser(dispatch) {

        const successHandler = ([info, torrent, rss]) => {
            return Promise.all(rss.data.map((flow) => UserService.getRssFlow(flow._id).request))
                .then((res) => {
                    rss.data = rss.data.map((flow, index) => ({...flow, feeds: res[index].data}));
                    dispatch(getUserSuccess({info: info.data, torrent: torrent.data, rss: rss.data}));
                });
        };
        dispatch(getUser());
        return Promise.all([
            ApiService.get('/api/users/me').request,
            ApiService.get('/api/jackerta/torrent/infos').request,
            ApiService.get('/api/rss').request
        ]).then(successHandler);
    }

    static toggleShareTorrent(dispatch, share) {
        return ApiService.post('/api/jackerta/torrent/infos/share', { share }).request
            .then((res) => dispatch(setTorrentShare(share)));
    }

    static changePassword(userId, {oldPassword, newPassword}) {
        return ApiService.put(`/api/users/${userId}/password`, {oldPassword: sha1(oldPassword), newPassword: sha1(newPassword)}).request
        .then((res) => {
            // NOTIF
        });
    }

    static createRssFlow(dispatch, name) {
        return ApiService.post('/api/rss', {name}).request
            .then((res) => {
                dispatch(createRssFlowSuccess({...res.data, feeds: []}));
            });
    }

    static getRssFlow(flowId) {
        return ApiService.get(`/api/rss/feeds/${flowId}`);
    }

    static emptyRssFlow(dispatch, flowId) {
        return ApiService.delete(`/api/rss/feeds/empty/${flowId}`).request
            .then((res) => {
                dispatch(emptyRssFlowSuccess({flowId}))
            });
    }

    static deleteRssFlow(dispatch, flowId) {
        return ApiService.delete(`/api/rss/${flowId}`).request
            .then((res) => {
                dispatch(deleteRssFlowSuccess({flowId}))
            });
    }

    static appendRssFlowFeed(dispatch, flowId, name, url) {
        return ApiService.post('/api/rss/feeds', {rss: flowId, name, url}).request
            .then((res) => {
                dispatch(appendRssFlowFeedSuccess(res.data));
            });
    }

    static removeRssFlowFeed(dispatch, flowId, feedId) {
        return ApiService.delete(`/api/rss/feeds/${feedId}`).request
            .then((res) => {
                dispatch(removeRssFlowFeedSuccess({flowId, feedId}));
            });
    }
};