import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AdminService from "../../../../services/Admin.service";
import './Role.css';

export default function Role({_id, level, limits, name}) {

    const dispatch = useDispatch();

    const [limitsForm, setLimitsForm] = useState(JSON.parse(JSON.stringify(limits)));
    
    const [newLimit, setNewLimit] = useState({name: '', type: '', value: ''});

    useEffect(() => {
        setLimitsForm(JSON.parse(JSON.stringify(limits)));
    }, [limits]);

    const setLimit = (limitType, limitValue) => {
        const limitForm = limitsForm.find((limitForm) => limitForm.type === limitType);
        limitForm.value = limitValue;
        setLimitsForm([...limitsForm]);
        AdminService.updateRoleLimit(dispatch, _id, limitForm);
    };

    const setNewLimitName = (ev) => {
        setNewLimit({...newLimit, name: ev.target.value});
    };

    const setNewLimitType = (ev) => {
        setNewLimit({...newLimit, type: ev.target.value});
    };

    const setNewLimitValue = (ev) => {
        setNewLimit({...newLimit, value: ev.target.value});
    };

    const createLimit = (ev) => {
        ev.preventDefault();
        AdminService.createRoleLimit(dispatch, _id, newLimit);
    }

    const removeLimit = (limitId) => {
        AdminService.removeRoleLimit(dispatch, _id, limitId);
    };

    return <div className="Role">
        <h4 className="Role-name">{name} (Niveau: {level})</h4>
        <form className="Role-limits" onSubmit={createLimit}>
            {limitsForm.map((limit) => <div key={limit._id} className="Role-limit">
                <p className="Role-limit-name">{limit.name}</p>
                <p className="Role-limit-type">{limit.type}</p>
                <input type="number" value={limit.value} onChange={(ev) => setLimit(limit.type, ev.target.value)} />
                <button className="danger block" type="button" onClick={() => removeLimit(limit._id)}>Supprimer</button>
            </div>)}
            <div className="Role-limits-create">
                <input type="text" value={newLimit.name} placeholder="Nom (Ex: JACKERTA)" onChange={setNewLimitName} />
                <input type="text" value={newLimit.type} placeholder="Type (Ex: space, quantity)" onChange={setNewLimitType} />
                <input type="number" value={newLimit.value} placeholder="Valeur" onChange={setNewLimitValue} />
                <button className="block" type="submit">Créer</button>
            </div>
        </form>
    </div>
}