import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Torrents.css';
import Card from '../../../Components/Card/Card';
import AdminService from '../../../services/Admin.service';
import Torrent from './Torrent/Torrent';
export default function Torrents() {
    const dispatch = useDispatch();
    const torrents = useSelector((state) => state.admin.torrents.data);
    const users = useSelector((state) => state.admin.users.data);

    useEffect(() => {
        AdminService.getTorrents(dispatch);
    }, []);

    return <Card label="Torrents">
        <table className='Torrents'>
            <thead>
                <tr>
                    <th className='Torrents-name'>Nom</th>
                    <th className='Torrents-size'>Taille</th>
                    <th className='Torrents-user'>Utilisateur</th>
                </tr>
            </thead>
            <tbody>
                {torrents
                    .filter((torrent) => !!torrent.datas)
                    .map((torrent) => <Torrent key={torrent._id} {...torrent} users={users}></Torrent>)}
            </tbody>
        </table>
    </Card>;
}