import React from 'react';
import './TorrentLines.css';
import TorrentLine from './TorrentLine/TorrentLine';
export default function TorrentLines({torrents, isShareable, isDeletable, isUserVisible}) {
    return <table className='TorrentLines'>
    <thead>
        <tr>
            <th className='TorrentLines-name'>Nom</th>
            <th>Taille</th>
            <th>Upload</th>
            <th>Date d'ajout</th>
            <th>Tracker</th>
            {isShareable && <th>Partager</th>}
            {isDeletable && <th>Supprimer</th>}
            {isUserVisible && <th>Utilisateur</th>}
        </tr>
    </thead>
    <tbody>{torrents.map((torrent) => <TorrentLine key={torrent._id} torrent={torrent} isShareable={isShareable} isDeletable={isDeletable} isUserVisible={isUserVisible} />)}</tbody>
  </table>;
}