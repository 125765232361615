import React, { useEffect } from 'react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../Components/Card/Card";
import AdminService from "../../../services/Admin.service";
import Role from './Role/Role';
import './Roles.css';

export default function Roles() {

    const dispatch = useDispatch();

    const roles = useSelector((state) => state.admin.roles.data);

    const [newRole, setNewRole] = useState({});
    
    useEffect(() => {
        AdminService.getRoles(dispatch);
    }, []);

    const setNewRoleName = (ev) => {
        setNewRole({...newRole, name: ev.target.value});
    };

    const setNewRoleLevel = (ev) => {
        setNewRole({...newRole, level: ev.target.value});
    };

    const createRole = (ev) => {
        ev.preventDefault();
        AdminService.createRole(dispatch, newRole);
    };

    return <Card label="Rôles">
        <div className="Roles-create">
            <h2>Créer un rôle</h2>
            <form onSubmit={createRole}>
                <input type="text" placeholder="Nom" onChange={setNewRoleName} required/>
                <input type="number" placeholder="Niveau" onChange={setNewRoleLevel} required/>
                <button type="submit">Créer</button>
            </form>

            </div>
        <div className="Roles-list">
            {
                roles.map((role) => <Role key={role._id} {...role}></Role>)
            }
            
        </div>
    </Card>
}