import React from 'react';
import './AccountInfoItem.css';

function AccountInfoItem(props) {
  return (
    <div className="AccountInfoItem">
      <div className="AccountInfoItem-info">
        <div className="AccountInfoItem-label">
          {props.label}
        </div>
        <div className="AccountInfoItem-value">
          {props.value}
        </div>
      </div>
      <div className="AccountInfoItem-extra">{props.children}</div>
    </div>
  );
}

export default AccountInfoItem;
