import { useSelector } from 'react-redux';

export default function useGuard() {

    const user = useSelector((state) => state.user.info);

    const hasRoleLevelGuard = (level) => {
        const userRoleLevel = user && user.role && user.role.level;
        return !!userRoleLevel && level <= userRoleLevel;
    };

    return { hasRoleLevelGuard };
}