import { useState, useEffect, useRef } from 'react';

export default function useElementVisible(isVisible) {
    const [isElementVisible, setIsElementVisible] = useState(isVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsElementVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isElementVisible, setIsElementVisible };
}