import React from 'react';
import useElementVisible from '../../hooks/useElementVisible';
import './Dropdown.css';
function Dropdown(props) {

    const {ref, isElementVisible, setIsElementVisible} = useElementVisible(false);

    return <div className='Dropdown'>
        <label onClick={() => setIsElementVisible(!isElementVisible)}>{props.label}</label>
        <div ref={ref} className="Dropdown-content">
            {isElementVisible && props.children}
        </div>
    </div>
}

export default Dropdown;