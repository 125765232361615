import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User.slice';
import torrentReducer from './features/Torrent/Torrent.slice';
import adminReducer from './features/Admin/Admin.slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    admin: adminReducer,
    torrent: torrentReducer
  },
});