import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import { Outlet } from "react-router-dom";
import {useAuthContext} from './context/AuthContext';
import UserService from './services/User.service';
import Header from './Header/Header';

function App() {
  
  const dispatch = useDispatch();
  const auth = useAuthContext();

  useEffect(() => {
    if(auth.token) {
      UserService.getUser(dispatch);
    }
  }, []);

  return (
    <div className="App">
      <div className="wallpaper"></div>
      <main className="App-main">
        <Outlet />
      </main>
      <footer className="App-footer">Copyright 2022</footer>
      <Header></Header>
    </div>
  );
}

export default App;
