import React from 'react';
import './Card.css';
function Card(props) {

    return <div className='Card'>
        {
            (props.label || props.actions) && <div className="Card-header">
                {props.label && <label>{props.label}</label>}
                <div className='Card-actions'>{props.actions}</div>
            </div>
        }
        <div className="Card-content">
            {props.children}
        </div>
    </div>
}

export default Card;