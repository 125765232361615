import React from 'react';
import './FileCard.css';
import { getFileLink, getFileType } from '../../../../utils/torrentDetail.utils';
import { getToken } from '../../../../services/Token.service';
import EnvironementService from '../../../../services/Environment.service';
import convertBitToBytes from '../../../../utils/convertBitToBytes.utils';
export default function FileCard({torrentId, path, file, goTo, addRssFeed, rss}) {
    return <div className={`FileCard ${file.type === 'directory' ? 'directory' : 'file'}`} onClick={() => goTo(file)}>
        <div className='FileCard-name'>{file.name}</div>
        <div className='FileCard-size FileCard-item'><span className='FileCard-label'>Taille:</span><span className='FileCard-value'>{convertBitToBytes(file.size)}</span></div>
        <div className='FileCard-type FileCard-item'><span className='FileCard-label'>Type:</span><span className='FileCard-value'>{file.type === 'directory' ? 'Dossier' : getFileType(file)}</span></div>
        {rss && rss.length > 0 && <div className='FileCard-rss FileCard-item'><span className='FileCard-label'>RSS:</span><span className='FileCard-value'>{<select className='sm block warning' defaultValue='' onClick={(ev) => {ev.stopPropagation()}} onChange={(ev) => addRssFeed(ev.target.value, file)}>
                <option value=''>Flux RSS</option>
                {rss.map((rss) => <option key={rss._id} value={rss._id}>{rss.name}</option>)}
            </select>}</span></div>}
        <div className='FileCard-item FileCard-download'><a
                className='block'
                onClick={(ev) => {ev.stopPropagation()}}
                href={EnvironementService.config.origin + getFileLink(torrentId, file.type, file.name, path, getToken())}
                target="_blank"
                rel="noopener noreferrer">
                <button className='sm block'>{file.type === 'directory' ? 'Télécharger le dossier' : 'Télécharger'}</button>
            </a>
        </div>
    </div>
}