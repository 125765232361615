import React, { useEffect, useState } from 'react';
import useResponsive from '../../../../../hooks/useResponsive';
import ResultCards from './ResultCards/ResultCards';
import ResultLines from './ResultLines/ResultLines';
import './Results.css';
export default function Results({searcherResults}) {

    const { breakpoint } = useResponsive();
    const [sort, setSort] = useState({prop: 'seeders', order: 'DESC'});
    const [results, setResults] = useState([]);

    useEffect(() => {
        const res = [...searcherResults];
        res.sort((a, b) => {
            if(sort.order === 'ASC') {
                return a[sort.prop] < b[sort.prop] ? -1 : a[sort.prop] > b[sort.prop] ? 1 : 0;
            } else {
                return a[sort.prop] < b[sort.prop] ? 1 : a[sort.prop] > b[sort.prop] ? -1 : 0;
            }
        });
        setResults(res);
    }, [searcherResults, sort]);

    return breakpoint === 'xl' ? <ResultLines results={results} sort={sort} setSort={setSort}></ResultLines> : <ResultCards  results={results} sort={sort} setSort={setSort}></ResultCards>
}