import React from 'react';
import { useState } from 'react';
import './Connection.css';
import Card from '../../Components/Card/Card';
import {useAuthContext} from '../../context/AuthContext';
import { useDispatch } from 'react-redux'
import UserService from '../../services/User.service';
function Connection() {

  const dispatch = useDispatch();

  const auth = useAuthContext();

  const [login, setLogin] = useState();
  
  const [password, setPassword] = useState();

  const handleEmailChange = (e) => {
    setLogin(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    auth.connect(login, password)
      .then(() => UserService.getUser(dispatch));
  }

  return (
    <div className='Connection grid-xs'>
      <Card label="Connexion">
        <form className="Connection-form" onSubmit={handleSubmit}>
          <input type="email" name="email" placeholder="Email" onChange={handleEmailChange} />
          <input type="password" name="password" placeholder="Mot de passe" onChange={handlePasswordChange} />
          <button type="submit">Se connecter</button>
        </form>
      </Card>
    </div>
    
  );
}

export default Connection;