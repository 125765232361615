import axios from "axios";
import ApiService from "../../services/Api.service";
import { socket } from "../../services/SocketIO.service";
import { getList, getListSuccess, updateListBySaving, updateListByRemoving, startSearch, setSearchResult, setSearchTorrentAdded, updateShare, getDetail, getDetailSuccess, getDetailFiles, getDetailFilesSuccess, deleteSuccess, getTrackers, getTrackersSuccess } from "./Torrent.slice";

export default class TorrentService {
    static getAll(dispatch, userId) {
        dispatch(getList());
        return ApiService.get('/api/jackerta/torrent').request
            .then((res) => {
                dispatch(getListSuccess({torrents: res.data, userId}));
                socket.on('jackerta:torrents:save', (torrent) => {
                    dispatch(updateListBySaving({userId, torrent}));
                  });
                  socket.on('jackerta:torrents:remove', (torrent) => {
                    dispatch(updateListByRemoving({userId, torrent}));
                  });
            });
    }

    static getTrackers(dispatch) {
        dispatch(getTrackers());
        return ApiService.get('/api/jackerta/tracker/search').request
            .then((res) => res.data.filter((tracker) => tracker.configured))
            .then((trackers) => {
                dispatch(getTrackersSuccess(trackers));
            });
    }

    static search(dispatch, {query, trackers}, nbSimultaneousRequest) {
        dispatch(startSearch({query, trackers}));

        const getRequest = (tracker) => {
            const request = {
                isStarted: false,
                isFinished: false,
                request: null,
                isCancelled: false,
                start: () => {
                    const req = ApiService.get(`/api/jackerta/search/${tracker}/${query}`);
                    request.isStarted = true;
                    req.request.then((res) => {
                        dispatch(setSearchResult({tracker, results: res.data, query}));
                        request.isFinished = true;
                        startNextRequest(requests);
                    })
                    .catch((err) => {
                        if(!axios.isCancel(err)) {
                            dispatch(setSearchResult({tracker, results: [], query}));
                            request.isFinished = true;
                            startNextRequest(requests);
                        }
                    });
                    request.request = req;
                }
            };
            return request;
        };

        const requests = trackers.map(getRequest);

        const startNextRequest = (requests) => {
            const request = requests.find((request) => !request.isCancelled && !request.isStarted && !request.isFinished);
            if(request) {
                request.start();
            }
        };

        for(let index = 0; index < nbSimultaneousRequest; index++) {
            startNextRequest(requests);
        }
        return requests;
    }

    static addFromFile(dispatch, file) {
        const formData = new FormData();
        formData.append('torrent', file);
        return ApiService.post(`/api/jackerta/add/file`, formData).request
            .then(() => {
                // NOTIF
            });
    }

    static addFromUrl(dispatch, url) {
        return ApiService.post(`/api/jackerta/add/url`, {url}).request
            .then(() => {
                // NOTIF
            });
    }

    static async addFromSearch(dispatch, torrent) {
        return ApiService.post(`/api/jackerta/add/torznab`, torrent).request
            .then(() => {
                // Notif
                dispatch(setSearchTorrentAdded(torrent));
            })
            .catch(() => {
                // Notif
            });
    }

    static async updateShare(dispatch, torrentId, isShare) {
        return ApiService.put(`/api/jackerta/torrent/${torrentId}`).request
            .then(() => {
                dispatch(updateShare({torrentId, isShare}));
            })
            .catch(() => {
                // Notif
            });
    }

    static async delete(dispatch, torrentId) {
        return ApiService.delete(`/api/jackerta/torrent/${torrentId}`).request
            .then((res) => {
                dispatch(deleteSuccess({torrentId}));
            })
            .catch(() => {
                // Notif
            });
    }

    static async getDetail(dispatch, torrentId) {
        dispatch(getDetail());
        return ApiService.get(`/api/jackerta/torrent/${torrentId}`).request
            .then((res) => {
                dispatch(getDetailSuccess(res.data));
            })
            .catch(() => {
                // Notif
            });
    }

    static async getDetailFiles(dispatch, torrentId, path) {
        dispatch(getDetailFiles());
        return ApiService.get(`/api/jackerta/torrent/${torrentId}/files?directory=${encodeURIComponent(path)}`).request
            .then((res) => {
                dispatch(getDetailFilesSuccess(res.data));
            })
            .catch(() => {
                // Notif
            });
    }
}