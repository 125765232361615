export default function convertBitToBytes(bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes === 0 ) return 0;
    if (typeof precision === 'undefined') precision = 1;
    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    const number = Math.floor(Math.log(bytes) / Math.log(1024));
    const value = (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);
    return value +  ' ' + units[number];
}
export function convertBitToGigaBytes(bytes) {
    return bytes / Math.pow(1024, 3);
}