import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getToken, removeToken, setToken as updateToken } from '../services/Token.service';
import UserService from '../services/User.service';
import {reset as resetUser} from '../User.slice';
import {reset as resetTorrent} from '../features/Torrent/Torrent.slice';
import {reset as resetAdmin} from '../features/Admin/Admin.slice';
export default function useAuth() {

  const dispatch = useDispatch();
  const [token, setToken] = useState(getToken());
  
  const saveToken = token => {
    updateToken(token);
    setToken(token);
  };

  const register = async (name, email, password) => {
    return UserService.register(name, email, password)
      .then((res) => saveToken(res.data.token));
  }

  const disconnect = () => {
    removeToken();
    setToken(null);
    dispatch(resetTorrent());
    dispatch(resetAdmin());
    dispatch(resetUser());
  };

  const connect = async (email, password) => {
    return UserService.connect(email, password)
      .then((res) => saveToken(res.data.token));
  };

  return {
    register,
    disconnect,
    token,
    connect
  }
}