import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../Components/Card/Card';
import AdminService from '../../../services/Admin.service';
import User from './User/User';

export default function Users() {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.admin.users.data);
    const roles = useSelector((state) => state.admin.roles.data);

    useEffect(() => {
        AdminService.getUsers(dispatch);
    }, [])
    return <Card label="Utilisateurs">
        <table>
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Validité</th>
                    <th>Supprimer</th>
                </tr>
            </thead>
            <tbody>
                    {users.map((user) => <User key={user._id} user={user} roles={roles}></User>)}
            </tbody>
        </table>
    </Card>
}