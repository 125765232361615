import React from 'react';
import { useDispatch } from 'react-redux';
import './Torrent.css';
import AdminService from '../../../../services/Admin.service';
import convertBitToBytes from '../../../../utils/convertBitToBytes.utils';
export default function Torrent(props) {
    const dispatch = useDispatch();
    const updateUser = (ev) => {
        AdminService.updateTorrentUser(dispatch, props._id, ev.target.value);
    };
    return <tr className='Torrent'>
        <td className='Torrent-name'>{props.datas.name}</td>
        <td className='Torrent-size'>{convertBitToBytes(props.datas.size)}</td>
        <td className='Torrent-user'><select className="block sm" defaultValue={props.user._id} onChange={updateUser}>{props.users.map((user) => <option key={user._id} value={user._id}>{user.name}</option>)}</select></td>
    </tr>
}