import React from 'react';
import './TorrentLines.css';
import TorrentLine from './TorrentLine/TorrentLine';

function TorrentLines(props) {

  return <table className="TorrentLines">
        <thead>
          <tr>
            <th className="TorrentLines-name">Nom</th>
            <th className="TorrentLines-eta">Restant</th>
            <th className="TorrentLines-speed">Vitesse</th>
            <th className="TorrentLines-size">Taille</th>
            {props.isUserVisible && <th className="TorrentLines-user">Utilisateur</th>} 
            {props.isShareable && <th className="TorrentLines-share">Partager</th>}
            {props.isDeletable && <th className="TorrentLines-remove">Supprimer</th>}
          </tr>
            
        </thead>
        <tbody>
        {props.torrents
          .map((torrent) => <TorrentLine
              key={torrent.hash}
              torrent={torrent}
              isUserVisible={props.isUserVisible}
              isDeletable={props.isDeletable}
              isShareable={props.isShareable}
          ></TorrentLine>)}
        </tbody>
    </table>;
}

export default TorrentLines;
