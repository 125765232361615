import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './Private.css';
import TorrentService from '../Torrent.service';
import TorrentComplete from '../TorrentComplete/TorrentComplete';
import TorrentIncomplete from '../TorrentIncomplete/TorrentIncomplete';
import Loader from '../../../Components/Loader/Loader';

function TorrentPrivate() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isLoaded = useSelector((state) => state.torrent.list.loaded);
  const isLoading = useSelector((state) => state.torrent.list.loading);
  const privates = useSelector((state) => state.torrent.list.privates);

  const getCompletes = () => privates.filter((torrent) => torrent.datas.percent === 1);
  const getIncompletes = () => privates.filter((torrent) => torrent.datas.percent < 1);

  useEffect(() => {
    if(!isLoaded && !isLoading && user.info && user.info._id) {
      TorrentService.getAll(dispatch, user.info._id);
    }
  }, [user, isLoaded, isLoading]);

  return (
    <div className="TorrentPrivate">
      {!!getIncompletes().length && <TorrentIncomplete torrents={getIncompletes()} isDeletable={true} isShareable={true} isUserVisible={false}></TorrentIncomplete>}
      {!!getCompletes().length && <TorrentComplete torrents={getCompletes()} isDeletable={true} isShareable={true} isUserVisible={false}></TorrentComplete>}
      {!getIncompletes().length && !getCompletes().length && isLoaded && <p className='TorrentPrivate-empty text-center'>Vous n'avez aucun torrent, <NavLink to='/torrent/ajout'>cliquer ici</NavLink> pour en ajouter !</p>}
      <Loader hasText="true" isVisible={isLoading}></Loader>
    </div>
  );
}

export default TorrentPrivate;
